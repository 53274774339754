<template>
    <div class="about_us">
        <div class="header" v-if="y > 20">
            <div class="header_container">
                <div class="header_logo" @click="$router.push('/')">
                    <img  src="../../assets/img/home/about_header.png" height="39" width="135"/>
                </div>
                <span class="header_right">
                    <span>关于我们</span>
                    <div class="logo_btn" @click="handleLogin">登录 | 注册</div>
                </span>
            </div>
        </div>
        <div class="blue_box">
            <div class="header_container">
                <div class="header_logo">
                    <img @click="$router.push('/')"  src="../../assets/img/home/home_logo_white.png" height="39" width="135"/>
                </div>
                <span class="header_right">
                        <span style="cursor: pointer">关于我们</span>
                        <div class="logo_btn" @click="handleLogin">登录 | 注册</div>
                </span>
            </div>
            <span class="container">专业的竞价推广数据分析平台</span>
        </div>
        <div class="center">
            <img class="left" src="../../assets/img/home/left_img.png" height="370" width="347"/>
            <div class="right">
                <div class="title">关于我们</div>
                <span>AI竞投——专业的竞价推广数据分析平台，是上海凭安网络科技有限公司旗下产品。</span><br>
                <span>基于庞大企业网站数据资源和海量关键词库，AI竞投实现了主流搜索引擎全覆盖、智能分析精准搜索、全网推广数据实时采集、广告投放动态监测。</span><br>
                    <span>AI竞投助您扩充词库、借鉴创意、优化策略。素材创意更丰富，关键词更多更优质，广告投放更高效，快速增加流量，有效提升ROI。</span>
                <br><br>
                <div class="title">联系我们</div>
                <div>客服电话：4006-173-173</div>
                <div>客服邮箱：aicha@pingansec.com</div>
                <br><br>
                <div class="jurisdiction" @click="$router.push('/claim')">查看会员权限>></div>
            </div>
        </div>
        <div class="bottom">
            <span>Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投（aijingtou.com）. All Rights Reserved. </span>
            <span class="link" @click="toLink">沪ICP备12039960号-38</span>
        </div>
    </div>
</template>

<script>
  import PinganAna from "pingansec-vue-ana";
  import {
    get_user_info
  } from '@/api/common'
  export default {
    name: "aboutUs",
    data() {
      return {
        y: 0
      }
    },
    mounted() {
      document.querySelector('body').onscroll = this.handleScroll;
    },
    methods: {
      handleLogin() {
        PinganAna.fire(3035)
        get_user_info().then(res => {
          if(res.data.result_code == 0){
            this.$router.push('/box/dataView');
          }
        })
      },
      toLink() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
      },
      handleScroll(e) {
        this.y = e.target.scrollingElement.scrollTop;
      },
    }
  }
</script>

<style scoped lang="scss">
.about_us {
    font-size: 16px;
    .header {
        width: 100%;
        height: 70px;
        background-color: #ffffff;
        box-shadow: 1px 2px 0px 0px #ebeff2;
        position: fixed;
        top: 0;
        z-index: 10 ;
        .header_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            width: 1200px;
            margin: 0 auto;
            box-shadow: 0 0 10px rgba(235, 239, 242, 0.5);
            .header_logo {
                position: relative;
            }
            .header_right {
                color: #444444;
                .logo_btn {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 90px;
                    height: 33px;
                    background-color: #ffffff;
                    border-radius: 6px;
                    border: solid 1px #318bf9;
                    box-sizing: border-box;
                    font-size: 14px;
                    cursor: pointer;
                    color: #1f81f8;
                    &:hover {
                        background-color: #e9f3fd;
                    }
                    &:active {
                        background-color: #f6f9fe;
                    }
                }
                span {
                    margin: 0 39px;
                    cursor: pointer;
                }
            }
        }
    }
    .blue_box {
        width: 100%;
        height: 408px ;
        background: url("../../assets/img/home/about_bg.png");
        background-size: cover;
        color: #ffffff;
        font-size: 45px;
        text-align: center;
        .header_container {
            width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: rgba(31, 129, 248, 0);
            margin: 0 auto;
            position: relative;
            top: 28px;
            .header_logo {
                position: relative;
                img {
                    cursor: pointer;
                }
            }
            .header_right {
                width: 191px;
                position: relative;
                color: #ffffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .logo_btn {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 90px;
                    height: 33px;
                    border-radius: 6px;
                    border: solid 1px #ffffff;
                    box-sizing: border-box;
                    font-size: 14px;
                    cursor: pointer;
                }
                span {
                    font-size: 16px;
                }
            }
        }
        .container {
            position: relative;
            top: 143px;
        }
    }
    .center {
        max-width: 1232px;
        margin: 0 auto;
        height: 836px;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        padding-top: 70px;
        .left {
        }
        .right {
            display: inline-block;
            width: 663px;
            color: #666666;
            line-height: 40px;
            .title {
                font-size: 30px;
                color: #333333;
                margin-bottom: 10px;
            }
            .jurisdiction {
                color: #1f81f8;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
    .bottom {
        width: 100%;
        height: 75px;
        text-align: center;
        color: #999999;
        font-size: 14px;
        line-height: 75px;
        .link {
            cursor: pointer;
        }
    }
}
</style>
